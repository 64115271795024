@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden !important;
  @apply font-body
}
body.crm{background-color: #F9FCFE }
.bg-white-transparent{background-color: rgba(255,255,255,.95)}

div#datepicker-root {
  z-index: 51;
  position: relative;
}

/* body{
  background-image: url("0x0-Model3_20.jpg");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-attachment: cover;
  background-size: 100%;
} */
.crm #root {
  flex-direction: column;
}
#root {
  display: flex;
  /* flex-direction: column; */
  height: 100%;
  min-height: 100%;
}
.bg-sld-blue{background-color: #212853}
.text-sld-blue{color: #212853}
@layer base {
  :root {
    --color-button-accent: red;
  }
  .huisstijl {
    --color-button-accent: green;
  }
}


select {
  background-color: white;
  -webkit-appearance: initial !important; 
}

@media (min-width: 1024px) {
  body :not(th) > select {
    height: 50px !important;
  }
}

option{font-family: "Poppins", sans-serif}
input[type='text']{-webkit-appearance: none}
h1 {
  @apply font-title text-xl lg:text-3xl font-bold mb-5;
  color: #707070
}
h3 {
  @apply font-title text-lg lg:text-xl my-5
}
.default-bg {
  @apply bg-[#E50823];
}
.btn-primary {
  @apply bg-[#E50823] text-white px-5 py-2 rounded-xl max-w-fit min-w-max	
}

.btn-secondary {
  @apply bg-[#212853] text-white px-5 py-2 rounded-xl max-w-fit min-w-max	
}

@font-face {
  font-family: "kentekenregular";
  src: local("kentekenregular"),
   url("./fonts/kenteken.ttf") format("truetype");
  font-weight: bold;
}

/* ICONS https://fontawesome.com/icons/percent?s=solid&f=classic */
@font-face {
  font-family: "fa-pro-solid";
  src: local("fa-pro-solid"),
   url("./fonts/fa-solid-900.ttf") format("truetype");
  font-weight: 800;
}

.fa{font-family: "fa-pro-solid"}
.fa-timeline:before{content: "\e29c"}
.fa-percent:before{content: "\25"};
.fa-hand-pointer:before{content: "\f25a"}
.fa-note:before{content: "\e1ff"}
.fa-book:before{content: "\f02d"}
.fa-file-pdf:before{content: "\f1c1"}
.fa-slider:before{content: "\e252"}
.fa-input-numeric:before{content: "\e1bd"}
.fa-lock:before{content: "\f023"}
.fa-unlock:before{content: "\f09c"}
.fa-arrows-rotate:before{content: "\f021"}
.fa-key:before{content: "\f084"}
.fa-pen-to-square:before{content: "\f044"} 
.fa-signature:before{content:"\f5b7"}
.fa-car-default:before{content:"\f1b9"}
.fa-envelope-circle-check:before{content: "\e4e8"}
.fa-car:before{content:"\f1b9"; font-size: 2rem}
.fa-circle-question:before{content:"\f059"; font-size: 2rem}
.fa-comment:before{content: "\f075"}
.fa-comments:before{content: "\f086"; font-size: 2rem}
.fa-badge-percent:before{content: "\f646"; font-size: 2rem}
.fa-calculator:before{content:"\f1ec"}
.fa-circle-user:before{content: "\f2bd"}
.fa-chevron-right:before{content: "\f054";}
.fa-chevron-left:before{content: "\f053"}
.fa-chevron-up:before{content: "\f077"}
.fa-chevron-down:before{content: "\f078"}

.fa-check:before{content: "\f00c"}
.fa-square-check:before{content: "\f14a"}
.fa-box-archive:before{content: "\f187"}
.fa-folder-open:before{content: "\f07c"}
.fa-dot:before{content: "\f111"}

/* .fa-period:before{content: "\2e"} */
.fa-circle-small:before{content: "\e122"}
.fa-pencil:before{content: "\f303"}
.fa-chart-line:before{content: "\f201"}
.fa-list:before{content:"\f03a"}
.fa-handshake:before{content: "\f2b5"}
.fa-address-card:before{content: "\f2bb"}
.fa-user-group:before{content: "\f500"}
.fa-user-pen:before{content: "\f4ff"}
.fa-user:before{content: "\f007"}
.fa-user-plus:before{content: "\f234"}
.fa-shield:before{content:"\f3ed"}
.fa-magnifying-glass:before{content: "\f002"}
.fa-car-md:before{content:"\f1b9"}
.fa-plus:before{content:"\2b"}
.fa-circle-xmark:before{content: "\f057"}
.fa-square-minus:before{content: "\f146"}
.fa-square-plus:before{content: "\f0fe"}
.fa-users:before{content: "\f0c0"}
.fa-lock:before{content: "\f023"}
.fa-trash:before{content: "\f1f8"}
.fa-trash-can:before{content: "\f2ed"}
.fa-square-xmark:before{content: "\f2d3"}
.fa-envelope:before{content: "\f0e0"}
.fa-square-info:before{content: "\f30f"}
.fa-arrow-down:before{content: "\f063"}
.fa-arrow-up:before{content: "\f062"}
.fa-message:before{content: "\f27a"}
.fa-download:before{content: "\f019"}
.fa-upload:before{content: "\f093"}
.fa-bars:before{content: "\f0c9"}
.fa-xmark:before{content: "\f00d"} 
.fa-euro-sign:before{content: "\f153"}
.fa-right-from-bracket:before{content: "\f2f5"}
.fa-square-question:before{content: "\f2fd"}
.fa-eye:before{content: "\f06e"}
.fa-eye-slash:before{content: "\f070"}
.fa-lock:before{content: "\f023"}
.fa-grid:before{content: "\e195"}
.fa-list:before{content: "\f03a"}
.fa-location-dot:before{content: "\f3c5"}
.fa-chevron-right:before{content: "\f054"}
.fa-chevron-down:before{content: "\f078"} 
.fa-xmark:before{content: "\f00d"}
.fa-trash:before{content: "\f1f8"}
.fa-engine:before{content: "\e16e"}
.fa-gas-pump:before{content: "\f52f"}
.fa-gauge-simple-max:before{content: "\f62b"}
.fa-calendar-days:before{content: "\f073"}
.fa-magnifying-glass:before{content: "\f002"} 
.fa-signal-bars:before{content: "\f690"}
.fa-road:before{content: "\f018"}
.fa-house:before{content: "\f015"}
.fa-pencil:before{content: "\f303"}
.fa-pen-to-square:before{content: "\f044"}
.fa-clipboard:before{content: "\f328"}
.fa-circle-check:before{content: "\f058"}
.fa-circle-question:before{content: "\f059"}
.fa-browser:before{content: "\f37e"}
/* .fa-traffic-light-slow:before{content: "\f639"} */
.fa-question:before{content: "\3f"}
.fa-exclamation:before{content: "\21"}
.fa-list-check:before{content: "\f0ae"}
.fa-star:before{content: "\f005"}
.fa-file-export:before{content: "\f56e"}
.fa-face-smile-plus:before{content: "\f5b9"}
.fa-paper-plane:before{content: "\f1d8"}
.fa-paperclip:before{content: "\f0c6"}
.fa-paperclip-vertical:before{content: "\e3c2"}
.fa-envelopes:before{content: "\e170"}
/* /ICONS */

.kenteken-plaat {
  font-family: kentekenregular;
  font-weight: normal;
  text-transform: uppercase;
  @apply bg-transparent text-center focus-visible:outline-none w-full		
}

.input {
  @apply px-3 py-1 rounded border-slate-300
}

.actie-blok {
  margin: -2px 0 0 -2px;
  @apply transition duration-300 ease-in-out bg-white border-2 cursor-pointer
}

tbody tr {background: white;}
.bg-gradient-reverse{
  background: rgb(246,144,30);
background: -moz-linear-gradient(90deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
background: linear-gradient(90deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6901e",endColorstr="#e50823",GradientType=1);
}
.bg-gradient {
  background: rgb(246,144,30);
  background: -moz-linear-gradient(270deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  background: -webkit-linear-gradient(270deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  background: linear-gradient(270deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6901e",endColorstr="#e50823",GradientType=1);
}
/* .bg-radial{
  background: rgb(246,144,30);
background: -moz-radial-gradient(circle, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
background: -webkit-radial-gradient(circle, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
background: radial-gradient(circle, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6901e",endColorstr="#e50823",GradientType=1);
} */
.bg-gradient-vertical:after {
  content: "";  
  background: rgb(246,144,30);
  background: -moz-linear-gradient(0deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  background: linear-gradient(0deg, rgba(246,144,30,1) 0%, rgba(229,8,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6901e",endColorstr="#e50823",GradientType=1);
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  /* background: rgba(255,255,255,.3); */
  border-radius: 100vh;
  border: 2px solid rgba(255,255,255,.5);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: black;
}

:root {
  --primary: #ddd;
  --secondary: #aaa;
}

/* Firefox */
.scrollbar-vertical * {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
.scrollbar-vertical *::-webkit-scrollbar {
  width: 15px;
}

.scrollbar-vertical *::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
  z-index: 555;
}

.scrollbar-vertical *::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@media print {
  @page {
    size: landscape;
  }
}